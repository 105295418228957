<template>
	<div id="settings-root" class="settings-root">
		SETTINGS ROOT COMPONENT
	</div>
</template>

<script>
export default {
	name: 'SettingsRootComponent',
	props: {},
	components: {},
	data() {
		return {}
	},
	computed: {},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {},
	watch: {}
}
</script>

<style lang="less" scoped>
#settings-root {

}
</style>
