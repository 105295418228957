<template>
	<div id="dashboard-root" class="dashboard-root">
		DASHBOARD ROOT COMPONENT
	</div>
</template>

<script>
export default {
	name: 'DashboardRootComponent',
	props: {},
	components: {},
	data() {
		return {}
	},
	computed: {},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {},
	watch: {}
}
</script>

<style lang="less" scoped>
#dashboard-root {

}
</style>
