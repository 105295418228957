<template>
    <div id="pos-login-root" class="pos-login-root" v-if="design==='ecruise'" @blur="onInput" >
        <div class="img-wrapper">
            <img class="logo" src="../../assets/img/eCruise_logo.png" alt="logo">
        </div>
        <div class="flex-row">
            <div class="auth-type crew-id">
                <div class="auth-form-wrapper">
                    <h2 class="title">Authorize</h2>
                    <div class="sub-title">with your Crew ID</div>
                    <form>
                        <label for="authorize" class="label">Crew ID</label>
                        <input id="authorize" @click="inFocus = 'crewId';" v-model="crewId" placeholder="Crew id"
                               class="authorize-input" :class="{'active': inFocus === 'crewId'}">
                        <button class="def-btn main-action-btn border-2 uppercase" @click.prevent="login('crewId')">AUTHORIZE</button>
                    </form>
                </div>
                <div class="scan-wrapper">
                    <div class="barcode" @click="openBarcodeScannerModal">
                        <img class="barcode_img" src="../../assets/img/barcode.png" alt="barcode">
                        <p>or scan your Crew member card</p>
                    </div>
                </div>

            </div>

            <div class="auth-type pincode">
                <div class="auth-form-wrapper">
                    <h2 class="title">Log in</h2>
                    <div class="sub-title">with Pincode</div>
                    <form>
                        <label for="login" class="label">Pincode</label>
                        <input @input="onInput" type="password" id="login" @click="inFocus = 'pinCode';" v-model="pinCode" placeholder="Pincode" class="login-input" :class="{'active': inFocus === 'pinCode'}">
                        <button class="def-btn main-action-btn border-2 uppercase"  @click.prevent="login('pinCode')">LOG IN</button>
                    </form>
                </div>
            </div>

        </div>
    </div >
    <div class="marg-logo" v-if="design === 'margaritaville'">
        <img class="logo" src="../../assets/img/logoMargaritaville.png" alt="logo">
    </div>
	<div class="marg-t" v-if="design==='margaritaville'">
		<div id="marg-login-root" class="marg-login-root"  @blur="onInput"  >
			<div class="flex-row">
				<div class="auth-type">
					<div class="auth-form-wrapper">
						<h2 class="title">Authorize</h2>
						<div class="sub-title">with your Employee ID</div>
						<form>
							<label for="auth" class="label">Employee ID</label>
							<input id="auth" @click="inFocus = 'crewId';" v-model="crewId" placeholder="Employee ID" class="authorize-input">
							<button class="def-btn main-action-btn uppercase border-2" @click.prevent="login('crewId')">AUTHORIZE</button>
						</form>
					</div>
					<div class="divider"></div>
					<div class="scan-wrapper">
						<div class="barcode" @click="openBarcodeScannerModal">
							<img v-if="design === 'ecruise'" class="barcode_img" src="../../assets/img/barcode.png" alt="barcode">
							<img v-if="design === 'margaritaville'" class="barcode_img" src="../../assets/img/barcode.svg" alt="barcode">
							<p>or scan your Employee card</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Keypad
				class="keypad"
				@clickOnKey="clickOnKey"
				@clear="clear"
		/>
	</div>
    
    <Keypad
	    v-if="design==='ecruise'"
        class="keypad"
        @clickOnKey="clickOnKey"
        @clear="clear"
    />
	<barcode-scanner-modal v-if="showBarcodeScannerModal" ref="barcode-scanner-modal" @submitBarcode="handleBarcode" @onModalClose="showBarcodeScannerModal = false"></barcode-scanner-modal>
</template>

<script>
import Keypad from "@/components/_shared/Keypad";
import {authService} from "@/_api/auth.api";
import store from "@/store";
import BarcodeScannerModal from "@/components/_modals/BarcodeScannerModal";

export default {
    name: 'LoginRootComponent',
    props: {},
    components: {Keypad, BarcodeScannerModal},
    data() {
        return {
            crewId: '',
            pinCode: '',
            barcode: '',
            inFocus: '',
	        showBarcodeScannerModal: false
        }
    },
    computed: {
        getPosId(){
            return this.$store.state.auth.posId
        },
        design(){
            return this.$store.state.UI
        }
    },
    created() {

    },
    mounted() {
    },
    beforeUnmount() {
    },
    methods: {
        login(par) {
            let obj = {}
			if (par === 'crewId') {
				obj = {
					crewId: this.crewId,
					posId: this.getPosId
				}
			} else if (par === 'pinCode') {
                obj = {
                    pinCode: this.pinCode,
					posId: this.getPosId
				}
			} else if (par === 'barcode') {
                obj = {
					posId: this.getPosId,
					barcode: this.barcode
				}
			}
            if (this.crewId || this.pinCode || this.barcode) {
                authService.login(obj).then(res => {
                    if (res) {
                        store.dispatch('loginUser', res.data)
                        this.$router.push('/pos');
                    }
                })
            }
        },
        clickOnKey(e) {
			if (this.design === 'margaritaville') {
				this.inFocus = 'crewId';
				this.crewId += e;
			} else {
				if (this.inFocus === 'crewId') {
					this.crewId += e
				} else if (this.inFocus === 'pinCode') {
					this.pinCode += e
				}
			}
        },
        clear() {
            if (this.inFocus === 'crewId') {
                this.crewId = this.crewId.slice(0, -1)
            } else if (this.inFocus === 'pinCode') {
                this.pinCode = this.pinCode.slice(0, -1)

            }
        },
	    openBarcodeScannerModal() {
			this.showBarcodeScannerModal = true;
			this.$nextTick(() => {
				this.$refs['barcode-scanner-modal'].openModal();
			})
	    },
	    handleBarcode(data) {
			this.barcode = data.toString();
		    this.showBarcodeScannerModal = false;
			this.login('barcode');
	    }
    },
    watch: {}
}
</script>

<style lang="less" scoped>
#pos-login-root {
    position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 840px;
    height: 516px;
    margin: 70px auto 0 auto;
    padding: 24px 57px 0 48px;
	.img-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo {
			margin: 0 auto;
			width: 120px;
			height: 108px;
		}
	}
    .flex-row {
        display: flex;
        margin-top: 36px;
        height: 324px;
        .auth-type {
            flex: 1 1 50%;
            position: relative;
	        &:first-child {
		        padding-right: 50px;
	        }
	        &:last-child {
		        border-left: 1px rgba(0, 0, 0, 0.1) solid;
		        padding-left: 50px;
	        }
            .scan-wrapper {
                /*position: absolute;
                bottom: 0;
                left: 41px;*/
                .barcode {
                    display: flex;
                    align-items: center;
                    border-top: 1px rgba(0, 0, 0, 0.1) solid;
                    p {
                        margin-left: 15px;
                    }
                }
            }
	        .auth-form-wrapper {
		        margin-bottom: 20px;
		        .title {
			        font-style: normal;
			        font-weight: normal;
			        font-size: 42px;
			        line-height: 56px;
			        color: #3F8EC5;
			        text-align: left;
			        margin: 0 0 4px 0;
		        }
		        .sub-title {
			        font-weight: 600;
			        font-size: 12px;
			        line-height: 16px;
			        text-align: left;
			        color: #1C282D;
			        margin: 0 0 44px 0;
		        }
		        form {
			        display: flex;
			        justify-content: flex-start;
			        align-items: flex-end;
			        flex-wrap: wrap;
			        .label {
				        font-weight: 600;
				        font-size: 14px;
				        line-height: 16px;
				        color: #1C282D;
				        text-align: left;
				        display: block;
				        width: 100%;
			        }
			        input {
				        width: 100%;
				        border: none;
				        background: #ECF4F9;
				        border-radius: 4px 4px 2px 2px;
				        height: 30px;
				        margin: 4px 0 32px 0;
				        border-bottom: #fff 0 solid;
				        transition: all 200ms;
				        font-size: 18px;
				        line-height: 20px;
				        align-items: center;
				        color: #1C282D;
				        padding-left: 11px;
				        &.active {
					        background: #FFFFFF;
					        border-radius: 4px 4px 2px 2px;
					        border: none;
					        border-bottom: #3F8EC5 2px solid;
				        }
			        }
		        }
		        .def-btn {
			        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			        width: 100%;
			        padding: 5px 20px;
			        height: 40px;
		        }
	        }
         
        }
    }
}
.marg-logo{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.marg-t {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: nowrap;
	#marg-login-root {
		margin: 0;
	}
	.keypad {
		/*width: 400px;
		bottom: 66px;*/
		margin: 0;
		z-index: 1;
	}
}
#marg-login-root {
    position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    //width: 312px;
    // height: 400px;
	width: 400px;
    margin: 10px auto 0 auto;
    padding: 44px;
    .flex-row {
        display: flex;
        // height: 324px;
        .auth-type{
	        width: 100%;
            .auth-form-wrapper {
	            .title{
		            font-style: normal;
		            font-weight: 400;
		            font-size: 42px;
		            line-height: 56px;
		            color: #017E8D;
		            text-align: left;
	            }
	            .sub-title{
		            font-style: normal;
		            font-weight: 600;
		            font-size: 12px;
		            line-height: 16px;
		            color: #304143;
		            text-align: left;
		            margin-top: -25px;
	            }
	            form{
		            margin-top: 44px;
		            .label {
			            font-weight: 600;
			            font-size: 14px;
			            line-height: 16px;
			            color: #1C282D;
			            text-align: left;
			            display: block;
			            margin-bottom: 5px;
		            }
		            input {
			            margin: 0 auto;
			            left: 0;
			            // width: 312px;
			            width: 100%;
			            height: 30px;
			            border: none;
			            // border-bottom: var(--second_theme_color) 2px solid;
			            font-size: 18px;
			            line-height: 20px;
			            align-items: center;
			            color: #1C282D;
			            // padding-left: 11px;
			            margin-bottom: 20px;
		            }
		            .def-btn{
			            width: 100%;
			            height: 40px;
			            // background-color: var(--second_theme_color);
			            // border-color: var(--second_theme_color);
			            /*width: 312px;
						height: 36px;
						left: calc(50% - 312px/2);
						top: calc(50% - 36px/2 + 49.5px);
						background: #E86D32;
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
						border: none;
						border-radius: 8px;
						font-weight: 600;
						font-size: 16px;
						line-height: 21px;
						text-transform: uppercase;
						color: #FFFFFF;
						margin-top: 32px;*/
		            }
	            }
            }
        }
        .divider{
            margin: 24px auto;
            border: none;
            border-bottom: 1px solid #000000;
            opacity: 0.1;
            width: 240px;
        }
        .barcode{
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                margin-left: 13px;
            }
        }
    }
}
.keypad {
    width: 400px;
    bottom: 66px;
    margin: 10px auto 0 auto;
}

@media (max-width: 1200px) {
	#pos-login-root {
		width: 70%;
	}
}


</style>
